import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GlobalStyle from './global.styles'
import Header from '../header/index'
import styled from 'styled-components'
import Footer from '../footer'

const BodyDiv = styled.div`
  transition: ${(props) => (props.active ? 'transform 0.2s linear' : 'none')};
  width: 100vw;
  position: relative;

  ${({ active }) =>
    active &&
    `
      transform: translateX(-250px);
    `}
`

const Layout = ({ isWhiteLogo = false, isTallFooter = false, children }) => {
  const [isShift, setShift] = useState(false)
  const detectShift = (shift) => {
    setShift(!shift)
  }

  return (
    <>
      <GlobalStyle />
      <BodyDiv active={isShift}>
        <Header isWhiteLogo={isWhiteLogo} handleShift={detectShift} />
        <main>{children}</main>
        <Footer isTallFooter={isTallFooter} />
      </BodyDiv>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isWhiteLogo: PropTypes.bool,
}

export default Layout
